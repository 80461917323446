/* Table of Contents
/* ------------------------------------------------------------

This is a development CSS file which is built to a minified
production stylesheet in assets/built/screen.css

1.  Global Styles
2.  Layout
3.  Special Templates
4.  Site Header
5.  Site Navigation
6.  Post Feed
7.  Single Post
  7.1. Subscribe Form
  7.2. Post Footer
  7.3. Comments
  7.4. Related Posts
  7.5. Floating Header
8.  Author Template
9.  Error Template
10. Subscribe Overlay
11. Site Footer

*/


/* 1. Global - Set up the things
/* ---------------------------------------------------------- */


/* Variables
/* ---------------------------------------------------------- */


:root {
    /* Colours */
    --blue: #3eb0ef;
    --green: #a4d037;
    --purple: #ad26b4;
    --yellow: #fecd35;
    --red: #f05230;
    --darkgrey: #15171A;
    --midgrey: #738a94;
    --lightgrey: #c5d2d9;
    --whitegrey: #e5eff5;
    --pink: #fa3a57;
    --brown: #a3821a;
}


/* Reset
/* ---------------------------------------------------------- */


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}


body {
    line-height: 1;
}


ol,
ul {
    list-style: none;
}


blockquote,
q {
    quotes: none;
}


blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}


table {
    border-spacing: 0;
    border-collapse: collapse;
}


img {
    max-width: 100%;
}


html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    font-family: sans-serif;

    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}


*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}


a {
    background-color: transparent;
}


a:active,
a:hover {
    outline: 0;
}


b,
strong {
    font-weight: bold;
}


i,
em,
dfn {
    font-style: italic;
}


h1 {
    margin: 0.67em 0;
    font-size: 2em;
}


small {
    font-size: 80%;
}


sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}


sup {
    top: -0.5em;
}


sub {
    bottom: -0.25em;
}


img {
    border: 0;
}


svg:not(:root) {
    overflow: hidden;
}


mark {
    background-color: #fdffb6;
}


code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}


button,
input,
optgroup,
select,
textarea {
    margin: 0; /* 3 */
    color: inherit; /* 1 */
    font: inherit; /* 2 */
}


button {
    overflow: visible;
    border: none;
}


button,
select {
    text-transform: none;
}


button,
html input[type="button"],
/* 1 */
input[type="reset"],
input[type="submit"] {
    cursor: pointer; /* 3 */

    -webkit-appearance: button; /* 2 */
}


button[disabled],
html input[disabled] {
    cursor: default;
}


button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
}


input {
    line-height: normal;
}


input:focus {
    outline: none;
}


input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; /* 1 */
    padding: 0; /* 2 */
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}


input[type="search"] {
    -webkit-box-sizing: content-box;
            box-sizing: content-box; /* 2 */

    -webkit-appearance: textfield; /* 1 */
}


input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}


legend {
    padding: 0; /* 2 */
    border: 0; /* 1 */
}


textarea {
    overflow: auto;
}


table {
    border-spacing: 0;
    border-collapse: collapse;
}


td,
th {
    padding: 0;
}


/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */


html {
    overflow-x: hidden;
    overflow-y: scroll;
    font-size: 62.5%;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


body {
    overflow-x: hidden;
    color: rgb(60, 72, 78);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.5rem;
    line-height: 1.6em;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background: #fff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-feature-settings: "liga" on;
}


::-moz-selection {
    text-shadow: none;
    background: rgb(203, 234, 251);
}


::selection {
    text-shadow: none;
    background: rgb(203, 234, 251);
}


hr {
    position: relative;
    display: block;
    width: 100%;
    margin: 2.5em 0 3.5em;
    padding: 0;
    height: 1px;
    border: 0;
    border-top: 1px solid rgb(227, 233, 237);
}


audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}


fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}


textarea {
    resize: vertical;
}


p,
ul,
ol,
dl,
blockquote {
    margin: 0 0 1.5em 0;
}


ol,
ul {
    padding-left: 1.3em;
    padding-right: 1.5em;
}


ol ol,
ul ul,
ul ol,
ol ul {
    margin: 0.5em 0 1em;
}


ul {
    list-style: disc;
}


ol {
    list-style: decimal;
}


li {
    margin: 0.5em 0;
    padding-left: 0.3em;
    line-height: 1.6em;
}


dt {
    float: left;
    margin: 0 20px 0 0;
    width: 120px;
    color: #15171A;
    font-weight: 500;
    text-align: right;
}


dd {
    margin: 0 0 5px 0;
    text-align: left;
}


blockquote {
    margin: 1.5em 0;
    padding: 0 1.6em 0 1.6em;
    border-left: #e5eff5 0.5em solid;
}


blockquote p {
    margin: 0.8em 0;
    font-size: 1.2em;
    font-weight: 300;
}


blockquote small {
    display: inline-block;
    margin: 0.8em 0 0.8em 1.5em;
    font-size: 0.9em;
    opacity: 0.8;
}


/* Quotation marks */


blockquote small:before {
    content: "\2014 \00A0";
}


blockquote cite {
    font-weight: bold;
}


blockquote cite a {
    font-weight: normal;
}


a {
    color: rgb(38, 168, 237);
    text-decoration: none;
}


a:hover {
    text-decoration: underline;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    line-height: 1.15;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}


h1 {
    margin: 0 0 0.5em 0;
    font-size: 5rem;
    font-weight: 700;
}


@media (max-width: 500px) {
    h1 {
        font-size: 2.2rem;
    }
}


h2 {
    margin: 1.5em 0 0.5em 0;
    font-size: 2rem;
}


@media (max-width: 500px) {
    h2 {
        font-size: 1.8rem;
    }
}


h3 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.8rem;
    font-weight: 500;
}


@media (max-width: 500px) {
    h3 {
        font-size: 1.7rem;
    }
}


h4 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.6rem;
    font-weight: 500;
}


h5 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.4rem;
    font-weight: 500;
}


h6 {
    margin: 1.5em 0 0.5em 0;
    font-size: 1.4rem;
    font-weight: 500;
}


body {
    background: #f4f8fb;
}


.img {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    border-radius: 100%;
}


.hidden {
    visibility: hidden;
    position: absolute;
    text-indent: -9999px;
}


/* 2. Layout - Page building blocks
/* ---------------------------------------------------------- */


.site-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
}


.site-main {
    z-index: 100;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}


/* Full width page blocks */


.outer {
    position: relative;
    padding: 0 4vw;
}


/* Centered content container blocks */


.inner {
    margin: 0 auto;
    max-width: 1040px;
    width: 100%;
}


/* Usage:

<div class="outer">
    <div class="inner">
        Centered content
    </div>
</div>

*/


/* 3. Special Template Styles
/* ---------------------------------------------------------- */


@media (min-width: 900px) {
    .home-template .post-feed,
    .tag-template .post-feed,
    .author-template .post-feed {
        margin-top: -70px;
        padding-top: 0;
    }
    .home-template .site-nav {
        position: relative;
        top: -70px;
    }
}


/* 4. Site Header
/* ---------------------------------------------------------- */


.site-header {
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    color: #fff;
    background: rgb(9, 10, 11) no-repeat center center;
    background-size: cover;
}


.site-header:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    display: block;
    background: rgba(0,0,0,0.18);
}


.site-header:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 10;
    display: block;
    height: 80px;
    background: -webkit-gradient(linear,left top, left bottom,from(rgba(0,0,0,0.1)),to(rgba(0,0,0,0)));
    background: linear-gradient(rgba(0,0,0,0.1),rgba(0,0,0,0));
}


.site-header.no-cover:before,
.site-header.no-cover:after {
    display: none;
}


.site-header-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10vw 4vw;
    min-height: 200px;
    max-height: 450px;
    text-align: center;
}


.site-title {
    z-index: 10;
    margin: 0;
    padding: 0;
    font-size: 3.8rem;
    font-weight: 700;
}


.site-logo {
    max-height: 45px;
}


.site-description {
    z-index: 10;
    margin: 0;
    padding: 5px 0;
    font-size: 2.2rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    opacity: 0.8;
}


@media (max-width: 500px) {
    .site-title {
        font-size: 3rem;
    }
    .site-description {
        font-size: 1.8rem;
    }
}


/* 5. Site Navigation
/* ---------------------------------------------------------- */


.site-nav {
    position: relative;
    z-index: 300;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    overflow-y: hidden;
    height: 40px;
    font-size: 1.2rem;
}


.site-nav-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    margin-right: 10px;
    padding-bottom: 80px;
    letter-spacing: 0.4px;
    white-space: nowrap;

    -ms-overflow-scrolling: touch;
}


/* Site Nav Hack Explanation (above):

What's happening above it .site-nav-left is set to overflow-x and allow sideways scrolling, so that when there isn't enough space for all nav items (either due to lots of nav items, or a small viewport), you can still scroll side-to-side to reach them.

The knock-on effect of this is ugly browser-scroll bars at the bottom, so 80px of padding-bottom and a 40px fixed height parent (.site-nav) hides that entirely. Slightly hacky code. But nice clean end-result.

*/


.site-nav-logo {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: block;
    margin-right: 24px;
    padding: 11px 0;
    color: #fff;
    font-size: 1.7rem;
    line-height: 1em;
    font-weight: bold;
    letter-spacing: -0.5px;
}


.site-nav-logo:hover {
    text-decoration: none;
}


.site-nav-logo img {
    display: block;
    width: auto;
    height: 21px;
}


.nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 0 -12px;
    padding: 0;
    list-style: none;
}


.nav li {
    display: block;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}


.nav li a {
    display: block;
    margin: 0;
    padding: 10px 12px;
    color: #fff;
    opacity: 0.8;
}


.nav li a:hover {
    text-decoration: none;
    opacity: 1;
}


.site-nav-right {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 40px;
}


.social-links {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}


.social-links a:last-of-type {
    padding-right: 20px;
}


.social-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0;
    padding: 10px;
    color: #fff;
    opacity: 0.8;
}


.social-link:hover {
    opacity: 1;
}


.social-link svg {
    height: 1.8rem;
    fill: #fff;
}


.social-link-fb svg {
    height: 1.5rem;
}


.social-link-wb svg {
    height: 1.6rem;
}


.social-link-wb svg path {
    stroke: #fff;
}


.social-link-rss svg {
    height: 1.9rem;
}


.subscribe-button {
    display: block;
    padding: 4px 10px;
    border: #fff 1px solid;
    color: #fff;
    font-size: 1.2rem;
    line-height: 1em;
    border-radius: 10px;
    opacity: 0.8;
}


.subscribe-button:hover {
    text-decoration: none;
    opacity: 1;
}


.rss-button {
    opacity: 0.8;
}


.rss-button:hover {
    opacity: 1;
}


.rss-button svg {
    margin-bottom: 1px;
    height: 2.1rem;
    fill: #fff;
}


@media (max-width: 700px) {
    .site-header {
        padding-right: 0;
        padding-left: 0;
    }
    .site-nav-left {
        margin-right: 0;
        padding-left: 4vw;
    }
    .site-nav-right {
        display: none;
    }
}


/* 6. Post Feed
/* ---------------------------------------------------------- */


.post-feed {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 0 -20px;
    padding: 40px 0 0 0;
}


.post-card {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 300px;
            flex: 1 1 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow: hidden;
    margin: 0 20px 40px;
    min-height: 300px;
    background: #fff center center;
    background-size: cover;
    border-radius: 5px;
    -webkit-box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
            box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}


.post-card:hover {
    -webkit-box-shadow: 0 0 1px rgba(39,44,49,0.10), 0 3px 16px rgba(39, 44, 49,0.07);
            box-shadow: 0 0 1px rgba(39,44,49,0.10), 0 3px 16px rgba(39, 44, 49,0.07);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: translate3D(0, -1px, 0);
            transform: translate3D(0, -1px, 0);
}


.post-card-image-link {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
}


.post-card-image {
    width: auto;
    height: 200px;
    background: #c5d2d9 no-repeat center center;
    background-size: cover;
}


.post-card-content-link {
    position: relative;
    display: block;
    padding: 25px 25px 0;
    color: #15171A;
}


.post-card-content-link:hover {
    text-decoration: none;
}


.post-card-tags {
    display: block;
    margin-bottom: 4px;
    color: #738a94;
    font-size: 1.2rem;
    line-height: 1.15em;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}


.post-card-title {
    margin-top: 0;
}


.post-card-content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}


.post-card-excerpt {
    font-family: Georgia, serif;
}


.post-card-meta {
    padding: 0 25px 25px;
}


.author-profile-image {
    margin-right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 100%;

    -o-object-fit: cover;

       object-fit: cover;
}


.post-card-author {
    font-size: 1.3rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}


/* Special Styling for home page grid (below):

The first (most recent) post in the list is styled to be bigger than the others and take over the full width of the grid to give it more emphasis. Wrapped in a media query to make sure this only happens on large viewports / desktop-ish devices.

 */


@media (min-width: 795px) {
    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 100%;
                flex: 1 1 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-image-link {
        position: relative;
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        border-radius: 5px 0 0 5px;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-image {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-content {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 357px;
                flex: 0 1 357px;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) h2 {
        font-size: 2.6rem;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) p {
        font-size: 1.8rem;
        line-height: 1.55em;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-content-link {
        padding: 30px 40px 0;
    }

    .home-template .post-feed .post-card:nth-child(6n+1):not(.no-image) .post-card-meta {
        padding: 0 40px 30px;
    }
}


.home-template .site-header:after {
    display: none;
}


/* Adjust some margins for smaller screens */


@media (max-width: 650px) {
    .post-feed {
        padding-top: 5vw;
    }
    .post-card {
        margin: 0 20px 5vw;
    }
}


/* 7. Single Post
/* ---------------------------------------------------------- */


.post-template .site-main,
.page-template .site-main {
    padding-bottom: 4vw;
    background: #fff;
}


.post-full {
    position: relative;
    z-index: 50;
}


/* ^ Required to make .post-full-content:before/after z-index stacking work */


.post-full-header {
    margin: 0 auto;
    padding: 6vw 3vw 3vw;
    max-width: 1040px;
    text-align: center;
}


@media (max-width: 500px) {
    .post-full-header {
        padding: 14vw 3vw 10vw;
    }
}


.post-full-meta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #738a94;
    font-size: 1.4rem;
    font-weight: 600;
    text-transform: uppercase;
}


.post-full-meta-date {
    color: #3eb0ef;
}


.post-full-title {
    margin: 0;
    color: rgb(9, 10, 11);
}


.date-divider {
    display: inline-block;
    margin: 0 6px 1px;
}


.post-full-image {
    margin: 0 -10vw -165px;
    height: 800px;
    background: #c5d2d9 center center;
    background-size: cover;
    border-radius: 5px;
}


@media (max-width: 1170px) {
    .post-full-image {
        margin: 0 -4vw -100px;
        height: 600px;
        border-radius: 0;
    }
}


@media (max-width: 800px) {
    .post-full-image {
        height: 400px;
    }
}


.post-full-content {
    position: relative;
    margin: 0 auto;
    padding: 70px 100px 0;
    min-height: 230px;
    font-family: Georgia, serif;
    font-size: 2.2rem;
    line-height: 1.6em;
    background: #fff;
}


@media (max-width: 1170px) {
    .post-full-content {
        padding: 5vw 7vw 0;
    }
}


@media (max-width: 800px) {
    .post-full-content {
        font-size: 1.9rem;
    }
}


.post-full-content:before {
    content: "";
    position: absolute;
    top: 15px;
    left: -5px;
    z-index: -1;
    display: block;
    width: 20px;
    height: 200px;
    background: rgba(39,44,49,0.15);
    -webkit-filter: blur(5px);
            filter: blur(5px);
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
}


.post-full-content:after {
    content: "";
    position: absolute;
    top: 15px;
    right: -5px;
    z-index: -1;
    display: block;
    width: 20px;
    height: 200px;
    background: rgba(39,44,49,0.15);
    -webkit-filter: blur(5px);
            filter: blur(5px);
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
}


.no-image .post-full-content {
    padding-top: 0;
}


.no-image .post-full-content:before,
.no-image .post-full-content:after {
    display: none;
}


.kg-card-markdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    max-width: 920px;
}


.post-full-content h1,
.post-full-content h2,
.post-full-content h3,
.post-full-content h4,
.post-full-content h5,
.post-full-content h6,
.post-full-content p,
.post-full-content ul,
.post-full-content ol,
.post-full-content dl,
.post-full-content pre,
.post-full-content blockquote,
.post-full-comments,
.footnotes {
    min-width: 100%;
}


.post-full-content li {
    word-break: break-word;
}


.post-full-content li p {
    margin: 0;
}


.post-template .kg-card-markdown > p:first-child {
    font-size: 1.25em;
    line-height: 1.5em;
}


.post-full-content a {
    color: #000;
    -webkit-box-shadow: #3eb0ef 0 -1px 0 inset;
            box-shadow: #3eb0ef 0 -1px 0 inset;
}


.post-full-content a:hover {
    color: #3eb0ef;
    text-decoration: none;
}


.post-full-content strong,
.post-full-content em {
    color: rgb(9, 10, 11);
}


.post-full-content small {
    display: inline-block;
    line-height: 1.6em;
}


.post-full-content li:first-child {
    margin-top: 0;
}


.post-full-content img,
.post-full-content video {
    display: block;
    margin: 1.5em auto;
    max-width: 1040px;
}


@media (max-width: 1040px) {
    .post-full-content img,
    .post-full-content video {
        width: 100%;
    }
}


/* Full bleed images (#full)
Super neat trick courtesy of @JoelDrapper

Usage (In Ghost edtior):

![img](/some/image.jpg#full)

*/


.post-full-content img[src$="#full"] {
    max-width: none;
    width: 100vw;
}


/* Image captions

Usage (In Ghost editor):

![img](/some/image.jpg)
<small>Your image caption</small>

*/


.post-full-content img + br + small {
    display: block;
    margin-top: -3em;
    margin-bottom: 1.5em;
}


.post-full-content iframe {
    margin: 0 auto;
}


.post-full-content blockquote {
    margin: 0 0 1.5em;
    padding: 0 1.5em;
    border-left: #3eb0ef 3px solid;
}


.post-full-content blockquote p {
    margin: 0 0 1em 0;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: italic;
}


.post-full-content blockquote p:last-child {
    margin-bottom: 0;
}


.post-full-content code {
    padding: 0 5px 2px;
    font-size: 0.8em;
    line-height: 1em;
    font-weight: 400!important;
    background: #e5eff5;
    border-radius: 3px;
}


.post-full-content pre {
    overflow-x: auto;
    margin: 1.5em 0 3em;
    padding: 20px;
    max-width: 100%;
    border: rgb(0, 0, 0) 1px solid;
    color: #e5eff5;
    font-size: 1.4rem;
    line-height: 1.5em;
    background: rgb(14, 15, 17);
    border-radius: 5px;
}


.post-full-content pre code {
    padding: 0;
    font-size: inherit;
    line-height: inherit;
    background: transparent;
}


.post-full-content pre code * {
    color: inherit;
}


.post-full-content .fluid-width-video-wrapper {
    margin: 1.5em 0 3em;
}


.post-full-content hr {
    margin: 4vw 0;
}


.post-full-content hr:after {
    content: "";
    position: absolute;
    top: -15px;
    left: 50%;
    display: block;
    margin-left: -10px;
    width: 1px;
    height: 30px;
    background: rgb(227, 233, 237);
    -webkit-box-shadow: #fff 0 0 0 5px;
            box-shadow: #fff 0 0 0 5px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}


.post-full-content h1,
.post-full-content h2,
.post-full-content h3,
.post-full-content h4,
.post-full-content h5,
.post-full-content h6 {
    color: rgb(9, 10, 11);
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}


.post-full-content h1 {
    margin: 0.5em 0 0.2em 0;
    font-size: 4.6rem;
    font-weight: 700;
}


@media (max-width: 500px) {
    .post-full-content h1 {
        font-size: 2.8rem;
    }
}


.post-full-content h2 {
    margin: 0.5em 0 0.2em 0;
    font-size: 3.6rem;
    font-weight: 700;
}


@media (max-width: 500px) {
    .post-full-content h2 {
        font-size: 2.6rem;
    }
}


.post-full-content h3 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.8rem;
    font-weight: 700;
}


@media (max-width: 500px) {
    .post-full-content h3 {
        font-size: 2.2rem;
    }
}


.post-full-content h4 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.8rem;
    font-weight: 700;
}


@media (max-width: 500px) {
    .post-full-content h4 {
        font-size: 2.2rem;
    }
}


.post-full-content h5 {
    display: block;
    margin: 0.5em 0;
    padding: 1em 0 1.5em;
    border: 0;
    color: #3eb0ef;
    font-family: Georgia,serif;
    font-size: 3.2rem;
    line-height: 1.35em;
    text-align: center;
}


@media (min-width: 1180px) {
    .post-full-content h5 {
        max-width: 1060px;
        width: 100vw;
    }
}


@media (max-width: 500px) {
    .post-full-content h5 {
        padding: 0 0 0.5em;
        font-size: 2.2rem;
    }
}


.post-full-content h6 {
    margin: 0.5em 0 0.2em 0;
    font-size: 2.3rem;
    font-weight: 700;
}


@media (max-width: 500px) {
    .post-full-content h6 {
        font-size: 2rem;
    }
}


.footnotes-sep {
    margin-bottom: 30px;
}


.footnotes {
    font-size: 1.5rem;
}


.footnotes p {
    margin: 0;
}


.footnote-backref {
    color: #3eb0ef !important;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}


/* Some grouped styles for smaller viewports */


@media (max-width: 500px) {
    .post-full-meta {
        font-size: 1.2rem;
        line-height: 1.3em;
    }
    .post-full-title {
        font-size: 2.9rem;
    }
    .post-full-image {
        margin-bottom: 4vw;
        height: 350px;
    }
    .post-full-content {
        padding: 0;
    }
    .post-full-content:before,
    .post-full-content:after {
        display: none;
    }
}


/* Tables */


.post-full-content table {
    display: inline-block;
    overflow-x: auto;
    margin: 0.5em 0 2.5em;
    max-width: 100%;
    width: auto;
    border-spacing: 0;
    border-collapse: collapse;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
    white-space: nowrap;
    vertical-align: top;
}


.post-full-content table {
    -webkit-overflow-scrolling: touch;
    background: radial-gradient(ellipse at left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 0 center, radial-gradient(ellipse at right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) 100% center;
    background-attachment: scroll, scroll;
    background-size: 10px 100%, 10px 100%;
    background-repeat: no-repeat;
}


.post-full-content table td:first-child {
    background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, rgba(255,255,255, 1)), to(rgba(255,255,255, 0)));
    background-image: linear-gradient(to right, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-size: 20px 100%;
    background-repeat: no-repeat;
}


.post-full-content table td:last-child {
    background-image: -webkit-gradient(linear, right top, left top, color-stop(50%, rgba(255,255,255, 1)), to(rgba(255,255,255, 0)));
    background-image: linear-gradient(to left, rgba(255,255,255, 1) 50%, rgba(255,255,255, 0) 100%);
    background-position: 100% 0;
    background-size: 20px 100%;
    background-repeat: no-repeat;
}


.post-full-content table th {
    color: #15171A;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-align: left;
    text-transform: uppercase;
    background-color: rgb(244, 248, 251);
}


.post-full-content table th,
.post-full-content table td {
    padding: 6px 12px;
    border: rgb(227, 236, 243) 1px solid;
}


/* 7.1. Subscribe Form
/* ---------------------------------------------------------- */


.subscribe-form {
    margin: 1.5em 0;
    padding: 6.5vw 7vw 7vw;
    border: rgb(237, 244, 248) 1px solid;
    text-align: center;
    background: rgb(244, 248, 251);
    border-radius: 7px;
}


.subscribe-form-title {
    margin: 0 0 3px 0;
    padding: 0;
    color: #15171A;
    font-size: 3.5rem;
    line-height: 1;
    font-weight: 700;
}


.subscribe-form p {
    margin-bottom: 1em;
    color: #738a94;
    font-size: 2.2rem;
    line-height: 1.55em;
    letter-spacing: 0.2px;
}


.subscribe-form form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 auto;
    max-width: 420px;
}


.subscribe-form .form-group {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}


.subscribe-email {
    display: block;
    padding: 10px;
    width: 100%;
    border: rgb(218, 226, 231) 1px solid;
    color: #738a94;
    font-size: 1.8rem;
    line-height: 1em;
    font-weight: normal;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
    border-radius: 5px;
    -webkit-transition: border-color 0.15s linear;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}


.subscribe-email:focus {
    outline: 0;
    border-color: rgb(190, 205, 213);
}


.subscribe-form button {
    display: inline-block;
    margin: 0 0 0 10px;
    padding: 0 20px;
    height: 41px;
    outline: none;
    color: #fff;
    font-size: 1.5rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.1);
    background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(79, 183, 240)),
    color-stop(60%, rgb(41, 160, 224)),
    color-stop(90%, rgb(41, 160, 224)),
    to(rgb(54, 166, 226))
    );
    background: linear-gradient(
    rgb(79, 183, 240),
    rgb(41, 160, 224) 60%,
    rgb(41, 160, 224) 90%,
    rgb(54, 166, 226)
    );
    background: -webkit-gradient(
    linear,
    left top, left bottom,
    );
    background: linear-gradient(
    );
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0 1px inset rgba(0,0,0,0.14);
            box-shadow: 0 0 0 1px inset rgba(0,0,0,0.14);

    -webkit-font-smoothing: subpixel-antialiased;
}


.subscribe-form button:active,
.subscribe-form button:focus {
    background: rgb(32, 156, 223);
}


@media (max-width: 650px) {
    .subscribe-form-title {
        font-size: 2.4rem;
    }
    .subscribe-form p {
        font-size: 1.6rem;
    }
}


@media (max-width: 500px) {
    .subscribe-form form {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .subscribe-form .form-group {
        width: 100%;
    }
    .subscribe-form button {
        margin: 10px 0 0 0;
        width: 100%;
    }
}


/* 7.2. Post Footer
/* ---------------------------------------------------------- */


.post-full-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 auto;
    padding: 3vw 0 6vw 0;
    max-width: 840px;
}


.author-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}


.author-card .author-profile-image {
    margin-right: 15px;
    width: 60px;
    height: 60px;
}


.author-card-name {
    margin: 0 0 2px 0;
    padding: 0;
    font-size: 2rem;
}


.author-card-name a {
    color: #15171A;
    font-weight: 700;
}


.author-card-name a:hover {
    text-decoration: none;
}


.author-card-content p {
    margin: 0;
    color: #738a94;
    line-height: 1.3em;
}


.post-full-footer-right {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-left: 20px;
}


.author-card-button {
    display: block;
    padding: 9px 16px;
    border: rgb(174, 187, 193) 1px solid;
    color: #738a94;
    font-size: 1.2rem;
    line-height: 1;
    font-weight: 500;
    border-radius: 20px;
    -webkit-transition: all ease 0.2s;
    transition: all ease 0.2s;
}


.author-card-button:hover {
    border-color: #3eb0ef;
    color: #3eb0ef;
    text-decoration: none;
}


/* 7.3. Comments
/* ---------------------------------------------------------- */


.post-full-comments {
    margin: 0 auto;
    max-width: 840px;
}


/* 7.4. Related posts
/* ---------------------------------------------------------- */


.read-next-feed {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin: 0 -20px;
    padding: 40px 0 0 0;
}


.read-next-card {
    position: relative;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 300px;
            flex: 1 1 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    overflow: hidden;
    margin: 0 20px 40px;
    padding: 25px;
    color: #fff;
    background: #15171A center center;
    background-size: cover;
    border-radius: 5px;
    -webkit-box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
            box-shadow: rgba(39,44,49,0.06) 8px 14px 38px, rgba(39, 44, 49, 0.03) 1px 3px 8px;
}


.read-next-card:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: linear-gradient(135deg, rgba(0,40,60,0.8) 0%,rgba(0,20,40,0.7) 100%);
    border-radius: 5px;

    -webkit-backdrop-filter: blur(2px);

            backdrop-filter: blur(2px);
}


.read-next-card-header {
    position: relative;
    z-index: 50;
    padding-top: 20px;
    text-align: center;
}


.read-next-card-header-sitetitle {
    display: block;
    font-size: 1.3rem;
    line-height: 1.3em;
    opacity: 0.8;
}


.read-next-card-header-title {
    margin: 0;
    padding: 0 20px;
    color: #fff;
    font-size: 3rem;
    line-height: 1.2em;
    letter-spacing: 1px;
}


.read-next-card-header-title a {
    color: #fff;
    font-weight: 300;
    text-decoration: none;
}


.read-next-card-header-title a:hover {
    text-decoration: none;
}


.read-next-divider {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 80px;
}


.read-next-divider svg {
    width: 40px;
    fill: transparent;
    stroke: #fff;

    stroke-width: 0.5px;
    stroke-opacity: 0.65;
}


.read-next-card-content {
    position: relative;
    z-index: 50;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 1.7rem;
}


.read-next-card-content ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    list-style: none;
}


.read-next-card-content li {
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    line-height: 1.25em;
    font-weight: 200;
    letter-spacing: -0.5px;
}


.read-next-card-content li a {
    display: block;
    padding: 20px 0;
    border-bottom: rgba(255,255,255,0.3) 1px solid;
    color: #fff;
    font-weight: 500;
    vertical-align: top;
    -webkit-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
}


.read-next-card-content li:first-of-type a {
    padding-top: 10px;
}


.read-next-card-content li a:hover {
    opacity: 1;
}


.read-next-card-footer {
    position: relative;
    margin: 15px 0 3px 0;
    text-align: center;
}


.read-next-card-footer a {
    color: #fff;
}


/* 7.5. Floating Header
/* ---------------------------------------------------------- */


.floating-header {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 60px;
    border-bottom: rgba(0,0,0,0.06) 1px solid;
    background: rgba(255,255,255,0.95);
    -webkit-transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translate3d(0, -120%, 0);
            transform: translate3d(0, -120%, 0);
}


.floating-active {
    visibility: visible;
    -webkit-transition: all 500ms cubic-bezier(0.22, 1, 0.27, 1);
    transition: all 500ms cubic-bezier(0.22, 1, 0.27, 1);
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}


.floating-header-logo {
    overflow: hidden;
    margin: 0 0 0 20px;
    font-size: 1.6rem;
    line-height: 1em;
    letter-spacing: -1px;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.floating-header-logo a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: #15171A;
    line-height: 1.1em;
    font-weight: 700;
}


.floating-header-logo a:hover {
    text-decoration: none;
}


.floating-header-logo img {
    margin: 0 10px 0 0;
    max-height: 20px;
}


.floating-header-divider {
    margin: 0 5px;
    line-height: 1em;
}


.floating-header-title {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    overflow: hidden;
    margin: 0;
    color: #2e2e2e;
    font-size: 1.6rem;
    line-height: 1.3em;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.floating-header-share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-left: 2%;
    font-size: 1.3rem;
    line-height: 1;
}


.floating-header-share a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}


.floating-header-share svg {
    width: auto;
    height: 16px;
    fill: #fff;
}


.floating-header-share-label {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
    color: rgba(0,0,0,0.7);
    font-weight: 500;
}


.floating-header-share-label svg {
    margin: 0 5px 0 10px;
    width: 18px;
    height: 18px;
    stroke: rgba(0,0,0,0.7);
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}


.floating-header-share-tw,
.floating-header-share-fb {
    display: block;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 60px;
    height: 60px;
    color: #fff;
    line-height: 48px;
    text-align: center;
    -webkit-transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1);
}


.floating-header-share-tw {
    background: #33b1ff;
}


.floating-header-share-fb {
    background: #005e99;
}


.progress {
    position: absolute;
    right: 0;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 2px;
    border: none;
    color: #3eb0ef;
    background: transparent;

    -webkit-appearance: none;

       -moz-appearance: none;

            appearance: none;
}


.progress::-webkit-progress-bar {
    background-color: transparent;
}


.progress::-webkit-progress-value {
    background-color: #3eb0ef;
}


.progress::-moz-progress-bar {
    background-color: #3eb0ef;
}


.progress-container {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: transparent;
}


.progress-bar {
    display: block;
    width: 50%;
    height: inherit;
    background-color: #3eb0ef;
}


@media (max-width: 900px) {
    .floating-header {
        height: 40px;
    }
    .floating-header-title,
    .floating-header-logo {
        font-size: 1.5rem;
    }
    .floating-header-share-tw,
    .floating-header-share-fb {
        width: 40px;
        height: 40px;
        line-height: 38px;
    }
}


@media (max-width: 800px) {
    .floating-header-logo {
        margin-left: 10px;
    }
    .floating-header-logo a {
        color: #2e2e2e;
    }
    .floating-header-title,
    .floating-header-divider {
        visibility: hidden;
    }
}


@media (max-width: 450px) {
    .floating-header-share-label {
        display: none;
    }
}


/* 8. Author Template
/* ---------------------------------------------------------- */


.site-header-content .author-profile-image {
    z-index: 10;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin: 0 0 20px 0;
    width: 100px;
    height: 100px;
    -webkit-box-shadow: rgba(255,255,255,0.1) 0 0 0 6px;
            box-shadow: rgba(255,255,255,0.1) 0 0 0 6px;
}


.site-header-content .author-bio {
    z-index: 10;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin: 5px 0 10px 0;
    max-width: 600px;
    font-size: 2rem;
    line-height: 1.3em;
    font-weight: 300;
    letter-spacing: 0.5px;
    opacity: 0.8;
}


.site-header-content .author-meta {
    z-index: 10;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0 10px 0;
    font-family: Georgia, serif;
    font-style: italic;
}


.site-header-content .author-location svg {
    height: 1.9rem;
    stroke: #fff;
}


.site-header-content .bull {
    display: inline-block;
    margin: 0 12px;
    opacity: 0.5;
}


.site-header-content .social-link:first-of-type {
    padding-left: 4px;
}


@media (max-width: 500px) {
    .site-header-content .author-bio {
        font-size: 1.8rem;
        line-height: 1.15em;
        letter-spacing: 0;
    }
    .author-location,
    .author-stats {
        display: none;
    }
}


/* 9. Error Template
/* ---------------------------------------------------------- */


.error-template .site-main {
    padding: 7vw 4vw;
}


.site-nav-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
}


.site-nav-center .site-nav-logo {
    margin-right: 0;
}


.error-message {
    text-align: center;
}


.error-code {
    margin: 0;
    font-size: 12vw;
    line-height: 1em;
    letter-spacing: -5px;
    opacity: 0.3;
}


.error-description {
    margin: 0;
    color: #738a94;
    font-size: 3rem;
    line-height: 1.3em;
    font-weight: 400;
}


@media (max-width: 800px) {
    .error-description {
        margin: 5px 0 0 0;
        font-size: 1.8rem;
    }
}


.error-link {
    display: inline-block;
    margin-top: 5px;
}


.error-template .post-feed {
    padding-top: 0;
}


/* 10. Subscribe Overlay
/* ---------------------------------------------------------- */


.subscribe-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background: rgba(0,25,40,0.97);
    opacity: 0;
    -webkit-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in;
    pointer-events: none;

    -webkit-backdrop-filter: blur(3px);

            backdrop-filter: blur(3px);
}


.subscribe-overlay:target {
    opacity: 1;
    pointer-events: auto;
}


.subscribe-overlay-content {
    position: relative;
    z-index: 9999;
    margin: 0 0 5vw 0;
    padding: 4vw;
    color: #fff;
    text-align: center;
}


.subscribe-overlay-logo {
    position: fixed;
    top: 23px;
    left: 30px;
    height: 30px;
}


.subscribe-overlay-title {
    display: inline-block;
    margin: 0 0 10px 0;
    font-size: 6rem;
    line-height: 1.15em;
}


.subscribe-overlay-description {
    margin: 0 auto 50px;
    max-width: 650px;
    font-family: Georgia, serif;
    font-size: 3rem;
    line-height: 1.3em;
    font-weight: 300;
    opacity: 0.8;
}


.subscribe-overlay form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 auto;
    max-width: 500px;
}


.subscribe-overlay .form-group {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}


.subscribe-overlay .subscribe-email {
    display: block;
    padding: 14px 20px;
    width: 100%;
    border: none;
    color: #738a94;
    font-size: 2rem;
    line-height: 1em;
    font-weight: normal;
    letter-spacing: 0.5px;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
    border-radius: 8px;
    -webkit-transition: border-color 0.15s linear;
    transition: border-color 0.15s linear;

    -webkit-appearance: none;
}


.subscribe-email:focus {
    outline: 0;
    border-color: rgb(190, 205, 213);
}


.subscribe-overlay button {
    display: inline-block;
    margin: 0 0 0 15px;
    padding: 0 25px;
    height: 52px;
    outline: none;
    color: #fff;
    font-size: 1.7rem;
    line-height: 37px;
    font-weight: 400;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0,0,0,0.1);
    background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(79, 183, 240)),
    color-stop(60%, rgb(41, 160, 224)),
    color-stop(90%, rgb(41, 160, 224)),
    to(rgb(54, 166, 226))
    );
    background: linear-gradient(
    rgb(79, 183, 240),
    rgb(41, 160, 224) 60%,
    rgb(41, 160, 224) 90%,
    rgb(54, 166, 226)
    );
    background: -webkit-gradient(
    linear,
    left top, left bottom,
    );
    background: linear-gradient(
    );
    border-radius: 8px;
    -webkit-box-shadow: 0 0 0 1px inset rgba(0,0,0,0.14);
            box-shadow: 0 0 0 1px inset rgba(0,0,0,0.14);

    -webkit-font-smoothing: subpixel-antialiased;
}


.subscribe-overlay button:active,
.subscribe-overlay button:focus {
    background: rgb(32, 156, 223);
}


.subscribe-overlay-close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
}


.subscribe-overlay-close:before {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}


.subscribe-overlay-close:after {
    content: "";
    position: absolute;
    top: 40px;
    right: 25px;
    display: block;
    width: 30px;
    height: 2px;
    background: #fff;
    opacity: 0.8;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}


.subscribe-overlay-close:hover {
    cursor: default;
}


/* 11. Site Footer
/* ---------------------------------------------------------- */


.site-footer {
    position: relative;
    padding-top: 20px;
    padding-bottom: 60px;
    color: #fff;
    background: rgb(0, 0, 0);
}


.site-footer-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    color: rgba(255,255,255,0.7);
    font-size: 1.3rem;
}


.site-footer-content a {
    color: rgba(255,255,255,0.7);
}


.site-footer-content a:hover {
    color: rgba(255,255,255,1);
    text-decoration: none;
}


.site-footer-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}


.site-footer-nav a {
    position: relative;
    margin-left: 20px;
}


.site-footer-nav a:before {
    content: "";
    position: absolute;
    top: 11px;
    left: -11px;
    display: block;
    width: 2px;
    height: 2px;
    background: #fff;
    border-radius: 100%;
}


.site-footer-nav a:first-of-type:before {
    display: none;
}


@media (max-width: 650px) {
    .site-footer-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .site-footer-nav a:first-child {
        margin-left: 0;
    }
}
